import "./App.scss";

import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useCheckPaymentStatusMutation,
  useGetCouponListQuery,
  useGetCustomerBagDataQuery,
  useGetCustomerHomeTryOnDataQuery,
  useGetCustomerWishlistQuery,
  useGetHeaderCategoriesDataQuery,
  useGetHeaderLabelsDataQuery,
  useGetHomeDataQuery,
  useSubscribeMutation,
} from "./redux/slices/homeSlice";
import { setWishlist } from "./redux/slices/wishlistSlice";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrderDetails,
  addToMyBag,
  removeOrderDetails,
  setMyBag,
  setOrderDetails,
} from "./redux/slices/myBagSlice";
import {
  CLOSE_BLACK_IC,
  LOGIN_MODEL_BANNER,
  PAGE_NOT_FOUND,
  PLACEHOLDER_NETRE,
} from "./assets/index.js";
import Skeleton from "react-loading-skeleton";
import { useFormik } from "formik";
import { setHomeTryOn } from "./redux/slices/homeTryonSlice.js";
import { Helmet } from "react-helmet";
import "react-loading-skeleton/dist/skeleton.css";
import { isValidEmail } from "./b2b/Footer/Footer";
import { $ } from "react-jquery-plugin";

const LoginSignup = lazy(() => import("./auth/LoginSignup"));
const Favorites = lazy(() => import("./b2b/Pages/Favorites/Favorites"));
const Footer = lazy(() => import("./b2b/Footer/Footer"));
const Header = lazy(() => import("./b2b/header/Header"));
const Home = lazy(() => import("./b2b/home/Home"));
const ProductPage = lazy(() =>
  import("./b2b/Pages/Favorites/ProductPage/ProductPage")
);
const Category = lazy(() => import("./b2b/Pages/Category/Category"));
const MyBag = lazy(() => import("./b2b/Pages/MyBag/MyBag"));
const ShoppingBag = lazy(() =>
  import("./b2b/Pages/ShoppingBag/ShoppingBag.jsx")
);
const ShoppingTray = lazy(() =>
  import("./b2b/Pages/ShoppingTray/ShoppingTray.jsx")
);
const OrderPlaced = lazy(() =>
  import("./b2b/Pages/OrderPlaced/OrderPlaced.jsx")
);
const ProfileDetails = lazy(() =>
  import("./b2b/Pages/ProfileDetails/ProfileDetails.jsx")
);
const Sidebar = lazy(() => import("./b2b/header/Sidebar.jsx"));
const Lens = lazy(() => import("./b2b/Pages/Lens/Lens.jsx"));
const TrackOrder = lazy(() =>
  import("./b2b/Pages/ProfileDetails/TrackOrder.jsx")
);

const ExchangeOrder = lazy(() =>
  import("./b2b/Pages/ProfileDetails/ExchangeOrder.jsx")
);
const CancelOrder = lazy(() =>
  import("./b2b/Pages/ProfileDetails/CancelOrder.jsx")
);
const ReturnOrder = lazy(() =>
  import("./b2b/Pages/ProfileDetails/ReturnOrder.jsx")
);
const ContactUs = lazy(() => import("./b2b/Pages/ContactUs/ContactUs.jsx"));
const BecomeRetailer = lazy(() =>
  import("./b2b/Pages/ContactUs/BecomeRetailer.jsx")
);
const Retailer = lazy(() => import("./b2b/Pages/Retailer/Retailer.jsx"));
const RetailerHeader = lazy(() => import("./b2b/header/RetailerHeader.jsx"));
const AboutUs = lazy(() => import("./b2b/Pages/BrandInfo/AboutUs.jsx"));
const PrivacyPolicy = lazy(() =>
  import("./b2b/Pages/BrandInfo/PrivacyPolicy.jsx")
);
const TermsCondition = lazy(() =>
  import("./b2b/Pages/BrandInfo/TermsCondition.jsx")
);
const ExchangePolicy = lazy(() =>
  import("./b2b/Pages/BrandInfo/ExchangePolicy.jsx")
);
const ReturnPolicy = lazy(() =>
  import("./b2b/Pages/BrandInfo/ReturnPolicy.jsx")
);

const OrderFailed = lazy(() =>
  import("./b2b/Pages/OrderPlaced/OrderFailed.jsx")
);
const HTO = lazy(() => import("./b2b/Pages/HTO/HTO.jsx"));
const Accessories = lazy(() =>
  import("./b2b/Pages/Accessories/Accessories.jsx")
);
const LensOnly = lazy(() => import("./b2b/Pages/Lens/LensOnly.jsx"));
const VisionWellness = lazy(() =>
  import("./b2b/Pages/VisionWellness/VisionWellness.jsx")
);
const StoreLocator = lazy(() =>
  import("./b2b/Pages/StoreLocator/StoreLocator.jsx")
);

const SiteMap = lazy(() => import("./b2b/Footer/SiteMap.js"));
const TrackInquiry = lazy(() =>
  import("./b2b/Pages/ProfileDetails/TrackInquiry.jsx")
);

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [sidebarToggle, setSidebarToggle] = useState(false);
  // lens screen steps
  const [lensStep, setLensStep] = useState(1);
  const dispatch = useDispatch();
  const user = localStorage.getItem("user");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const myBagitems = useSelector((state) => state.myBagData.items);
  const orderDetails = useSelector((state) => state?.myBagData?.orderDetails);

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  const {
    data: homeData,
    isSuccess: homeDataIsSuccess,
    isLoading: homeDataIsLoading,
    isError: homeDataIsError,
    error: homeDataError,
  } = useGetHomeDataQuery();

  const {
    data: headerLabelsData,
    isSuccess: headerLabelsSuccess,
    isLoading: headerLabelsLoading,
    isError: headerLabelsIsError,
    error: headerLabelsError,
  } = useGetHeaderLabelsDataQuery();

  const {
    data: headerCategoriesData,
    isSuccess: headerCategoriesSuccess,
    isLoading: headerCategoriesLoading,
    isError: headerCategoriesIsError,
    error: headerCategoriesError,
  } = useGetHeaderCategoriesDataQuery();

  const {
    data: wishlistData,
    isSuccess: wishlistSuccess,
    isLoading: wishlistLoading,
    isError: wishlistIsError,
    error: wishlistError,
  } = useGetCustomerWishlistQuery({ user: user }, { skip: !user });

  useEffect(() => {
    if (wishlistSuccess) {
      const data = wishlistData.wishlist;
      dispatch(setWishlist(data));
    } else if (wishlistIsError) {
      dispatch(setWishlist([]));
    }
  }, [wishlistData, wishlistSuccess, wishlistIsError]);

  const {
    data: customerBagData,
    isSuccess: customerBagIsSuccess,
    isLoading: customerBagIsLoading,
    isError: customerBagIsError,
    error: customerBagError,
  } = useGetCustomerBagDataQuery({ user: user }, { skip: !user });

  const {
    data: customerHomeTryOnData,
    isSuccess: customerHomeTryOnDataIsSuccess,
    isLoading: customerHomeTryOnDataIsLoading,
    isError: customerHomeTryOnDataIsError,
    error: customerHomeTryOnDataError,
  } = useGetCustomerHomeTryOnDataQuery({ user: user }, { skip: !user });

  // const {
  //   data: couponData,
  //   isSuccess: couponDataIsSuccess,
  //   isLoading: couponDataIsLoading,
  //   isError: couponDataIsError,
  //   error: couponDataError,
  // } = useGetCouponListQuery({ user: user, couponType: "" }, { skip: !user });

  const findAutoAppliedCouponData = (
    couponArray,
    couponCode,
    minimumAmount
  ) => {
    const data = couponArray?.find((item) => {
      return (
        minimumAmount > item?.minimumAmount &&
        item?.couponCode === couponCode &&
        item
      );
    });
    return data;
  };

  const calculateCartTotalWithoutOffer = () => {
    return customerBagData?.cart?.reduce((total, item) => {
      return total + item?.prices?.originalPrice * item?.quantity;
    }, 0);
  };

  useEffect(() => {
    if (customerBagIsSuccess) {
      const data = customerBagData.cart;
      dispatch(setMyBag(data));
      // dispatch(
      //   setOrderDetails({
      //     couponCode: findAutoAppliedCouponData(
      //       couponData,
      //       customerBagData?.appliedCoupon,
      //       calculateCartTotalWithoutOffer()
      //     ),
      //   })
      // );
    } else if (customerBagError && user !== null) {
      dispatch(setMyBag([]));
    }
  }, [customerBagData, customerBagIsSuccess, customerBagIsError]);

  useEffect(() => {
    if (customerHomeTryOnDataIsSuccess) {
      const data = customerHomeTryOnData.inquiryTray;
      dispatch(setHomeTryOn(data));
    } else if (customerHomeTryOnDataIsError && user !== null) {
      dispatch(setMyBag([]));
    }
  }, [
    customerHomeTryOnData,
    customerHomeTryOnDataIsSuccess,
    customerHomeTryOnDataIsError,
  ]);

  const [isRetailerPage, setIsRetailerPage] = useState(false);

  useEffect(() => {
    setIsRetailerPage(false);
  }, [location.pathname]);

  const eyeFramesRef = useRef(null);
  const newArrivalsRef = useRef(null);
  const ourStoreRef = useRef(null);
  const contactUsRef = useRef(null);

  // ===================== CLEAR WISHLIST AND CART ON LOGOUT ===================

  // useEffect(() => {
  //   if (!user) {
  //     dispatch(setMyBag([]));
  //     dispatch(setWishlist([]));
  //     dispatch(setHomeTryOn([]));
  //   }
  // }, [user]);

  // user subscribe email

  const [formLoader, setFormLoader] = useState(false);
  const [subscribe] = useSubscribeMutation();
  const subscribeFormik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async function (values) {
      if (!values?.email) {
        toast.error("Email id is Required!");
        return;
      } else if (!isValidEmail(values?.email)) {
        toast.error("Email is not valid!");
        return;
      }

      setFormLoader(true);
      subscribe({
        body: {
          email: values?.email,
        },
      }).then((res) => {
        if (res?.data) {
          subscribeFormik?.resetForm();
          toast.success(res?.data.message);
          // setSuccessMsg(res?.data.message);
        } else {
          // console.log(res?.error?.data?.message);
          toast.error(`${res?.error?.data?.message}`);
          // setErrorMsg(res?.error?.data?.message);
        }
        // setTimeout(() => {
        //   setSuccessMsg("");
        //   setErrorMsg("");
        // }, 5000);
        setFormLoader(false);
      });
    },
    validate: (values) => {
      // let errors = {};
      // if (!values?.email) {
      //   toast.error("Email id is Required!");
      // } else if (!isValidEmail(values?.email)) {
      //   toast.error("Email is not valid!");
      // }
      // return errors;
    },
  });

  // =================== CHECK COUPON IS VALID OR NOT WHEN CART CHANGE ====================
  const subTotal = () => {
    return myBagitems?.reduce((total, item) => {
      // Calculate the product price based on quantity
      const productPrice = item?.prices?.price * item?.quantity;

      // Calculate the total lens price for this product (sum of all lenses)
      const totalLensPrice = item?.lensDetails?.reduce((lensTotal, lens) => {
        // Add prices of all addons for this lens
        const addonsPrice = lens.addons.reduce((addonsSum, addon) => {
          return addonsSum + addon.price;
        }, 0);
        return addonsPrice + lensTotal + lens?.price || 0;
      }, 0);

      // Add the total lens price to the product price
      const itemTotal = productPrice + (totalLensPrice || 0);

      return total + itemTotal;
    }, 0);
  };

  const checkCouponCodeIsValid = (coupon) => {
    const endTime = new Date(coupon?.endTime);
    const currentTime = new Date();

    if (currentTime > endTime) {
      return false;
    }
    if (subTotal() < coupon?.minimumAmount) {
      return false;
    }
    if (coupon?.couponQuantity === 0) {
      return false;
    }
    return true;
  };

  const checkEndDateOfCoupon = (coupon) => {
    const endTime = new Date(coupon?.endTime);
    const currentTime = new Date();
    if (currentTime > endTime || coupon?.couponQuantity === 0) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    if (orderDetails?.couponCode) {
      if (
        checkCouponCodeIsValid(orderDetails?.couponCode) &&
        checkEndDateOfCoupon(orderDetails?.couponCode)
      ) {
        dispatch(
          addOrderDetails({
            ...orderDetails,
            couponCode: orderDetails?.couponCode,
          })
        );
      } else {
        dispatch(
          removeOrderDetails({
            items: myBagitems,
            orderDetails: {
              isGift: orderDetails?.isGift,
              giftMessage: orderDetails?.giftMessage,
              couponCode: null,
            },
          })
        );
      }
    }
  }, [myBagitems]);

  // =========== LOGIN MODEL ==============

  const loginModelRef = useRef();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    // Check if the user is logged in
    const user = localStorage.getItem("user");
    const hasClosedModal = localStorage.getItem("hasClosedModal");

    if (
      !user &&
      !hasClosedModal &&
      !["/login", "/signup"].includes(location.pathname)
    ) {
      const timer = setTimeout(() => {
        setIsModalVisible(true);
      }, 60000); // Show modal after 10 seconds

      return () => clearTimeout(timer); // Cleanup timeout
    }
  }, [location]);

  const handleCloseModal = () => {
    setIsModalVisible(false);
    localStorage.setItem("hasClosedModal", "true");
  };

  useEffect(() => {
        $("#loader-wrapper").fadeOut();
    }, []);

  return (
    <Suspense fallback={<FallbackLoader />}>
      <div
        className={`App ${
          location.pathname.includes("retailer") &&
          location.pathname !== "/become-retailer"
            ? "pt-[68px]"
            : "lg:pt-[140px] emd:pt-[45px] small:pt-[45px]"
        } `}
        onClick={() => {
          isModalVisible && handleCloseModal();
        }}
      >
        {homeData?.seo && (
          <Helmet>
            <title>{homeData?.seo?.meta_title}</title>
            <meta
              name="description"
              content={homeData?.seo?.meta_description}
            />
            <meta name="keywords" content={homeData?.seo?.meta_keywords} />
          </Helmet>
        )}
        {location.pathname.includes("retailer") &&
        location.pathname !== "/become-retailer" ? (
          <RetailerHeader
            eyeFramesRef={eyeFramesRef}
            newArrivalsRef={newArrivalsRef}
            ourStoreRef={ourStoreRef}
            contactUsRef={contactUsRef}
          />
        ) : (
          <Header
            isRetailerPage={isRetailerPage}
            sidebarToggle={sidebarToggle}
            setSidebarToggle={setSidebarToggle}
            headerLabelsData={headerLabelsData}
            headerCategoriesData={headerCategoriesData}
          />
        )}
        {/* {isRetailerPage ? (
        <RetailerHeader />
      ) : (
        <Header
          isRetailerPage={isRetailerPage}
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
          headerLabelsData={headerLabelsData}
        />
      )} */}
        {/* <Header
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
          headerLabelsData={headerLabelsData}
        /> */}
        <MyBag
          setLensStep={setLensStep}
          blueCutLensIdDataLabel={headerLabelsData}
        />
        <Sidebar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
          headerLabelsData={headerLabelsData}
          headerCategoriesData={headerCategoriesData}
        />
        <Routes>
          <Route
            path="/sitemap"
            element={<SiteMap headerCategoriesData={headerCategoriesData} />}
          />
          <Route
            exact
            path="/"
            element={
              <Home
                blueCutLensIdDataLabel={headerLabelsData}
                homeData={homeData?.home}
              />
            }
          />
          <Route
            exact
            path="/retailer/:retailerName"
            element={
              <Retailer
                setIsRetailerPage={setIsRetailerPage}
                eyeFramesRef={eyeFramesRef}
                newArrivalsRef={newArrivalsRef}
                ourStoreRef={ourStoreRef}
                contactUsRef={contactUsRef}
              />
            }
          />
          <Route
            exact
            path="/products/:id"
            element={<ProductPage blueCutLensIdDataLabel={headerLabelsData} />}
          />
          <Route
            path="/categories/:name"
            element={<Category blueCutLensIdDataLabel={headerLabelsData} />}
          />
          <Route
            path="/search/:searchProductParams?"
            element={<Category blueCutLensIdDataLabel={headerLabelsData} />}
          />
          <Route
            path="/best-sellers"
            element={
              <Category
                blueCutLensIdDataLabel={headerLabelsData}
                pageBannerData={homeData?.page_banners}
              />
            }
          />
          <Route
            path="/offers"
            element={
              <Category
                blueCutLensIdDataLabel={headerLabelsData}
                pageBannerData={homeData?.page_banners}
              />
            }
          />
          <Route
            path="/favourite"
            element={
              <>
                <Favorites blueCutLensIdDataLabel={headerLabelsData} />
              </>
            }
          />
          <Route
            path="/shopping-bag"
            element={
              <ShoppingBag
                setLensStep={setLensStep}
                blueCutLensIdDataLabel={headerLabelsData}
              />
            }
          />
          <Route path="/profile-details" element={<ProfileDetails />} />
          <Route path="/order-check/:orderId" element={<OrderCheck />} />
          <Route
            path="/order-placed/:orderId"
            element={<OrderPlaced blueCutLensIdDataLabel={headerLabelsData} />}
          />
          <Route
            path="/order-failed/:orderId"
            element={<OrderFailed blueCutLensIdDataLabel={headerLabelsData} />}
          />
          <Route path="/shopping-tray" element={<ShoppingTray />} />
          <Route
            path="/home-tryon"
            element={<HTO pageBannerData={homeData?.page_banners} />}
          />
          <Route path="/accessories" element={<Accessories />} />
          <Route
            path="/track-order/:trackOrderId/:trackProductSku"
            element={<TrackOrder />}
          />
          <Route
            path="/track-inquiry/:trackInquiryId"
            element={<TrackInquiry />}
          />
          <Route
            path="/exchange-order/:exchangeOrderId/:exchangeProductId/:exchangeProductSku"
            element={<ExchangeOrder />}
          />
          <Route
            path="/cancel-order/:cancelOrderId/:cancelProductId"
            element={<CancelOrder />}
          />
          <Route
            path="/return-order/:returnOrderId/:returnProductId/:returnProductSku"
            element={<ReturnOrder />}
          />
          <Route
            path="/login"
            element={<LoginSignup blueCutLensIdDataLabel={headerLabelsData} />}
          />
          <Route
            path="/signup"
            element={<LoginSignup blueCutLensIdDataLabel={headerLabelsData} />}
          />
          <Route
            path="/lens"
            element={<Lens step={lensStep} setStep={setLensStep} />}
          />
          <Route
            path="/lens-only"
            element={<LensOnly pageBannerData={homeData?.page_banners} />}
          />
          <Route
            path="/vision-wellness"
            element={<VisionWellness pageBannerData={homeData?.page_banners} />}
          />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/become-retailer" element={<BecomeRetailer />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          <Route path="/exchange-policy" element={<ExchangePolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/store-locator" element={<StoreLocator />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {location.pathname !== "/become-retailer" &&
        location.pathname.includes("retailer") ? null : (
          <Footer lensStep={lensStep} />
        )}

        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined}
          transition={Bounce}
          toastStyle={{
            // opacity: 0.9,
            backgroundColor: "#fcf5ccbb",
          }}
          style={{ marginTop: "68px" }} // Add desired top margin
        />
        {isModalVisible && (
          <>
            <div className="fixed left-0 top-0 z-9999 flex h-full min-h-screen w-full items-center justify-center bg-black/50 px-4 py-5">
              <div
                ref={loginModelRef}
                className={`border-0 overflow-hidden cursor-pointer shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none`}
              >
                <div className="flex items-center justify-between relative">
                  <img
                    src={LOGIN_MODEL_BANNER}
                    alt=""
                    className="max-h-[90vh] object-contain"
                    onClick={() => navigate("/login")}
                  />
                  <img
                    src={CLOSE_BLACK_IC}
                    alt=""
                    className="absolute right-0 top-0 p-2 "
                    onClick={() => handleCloseModal()}
                  />
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-999 bg-black"></div>
          </>
        )}
      </div>
    </Suspense>
  );
}

export default App;

function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "instant",
  });
  // scrooll .App to top
  const scrollArea = document.querySelector(".App");
  if (!scrollArea) return;
  scrollArea.scrollTo({
    top: 0,
    left: 0,
    behavior: "instant",
  });
}

export const PageNotFound = () => {
  return (
    <Link
      to="/"
      style={{
        width: "100%",
        height: "70vh",
        objectFit: "contain",
        display: "block",
      }}
    >
      <img
        style={{
          height: "100%",
          width: "100%",
          verticalAlign: "middle",
          objectFit: "contain",
        }}
        src={PAGE_NOT_FOUND}
        alt="PAGE NOT FOUND"
      />
    </Link>
  );
};

const OrderCheck = () => {
  const { orderId } = useParams();
  const [checkPaymentStatus] = useCheckPaymentStatusMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (orderId) {
      console.log(orderId);
      const body = {
        order_id: orderId,
      };
      checkPaymentStatus({ body: body })
        .then((res) => {
          if (res.data.status == "Placed") {
            navigate(`/order-placed/${orderId}`);
          } else {
            navigate(`/order-failed/${orderId}`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [orderId]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999999,
        width: "100vw",
        maxWidth: "100%",
        height: "100dvh",
        overflow: "hidden",
      }}
    >
      <Skeleton height={"100%"} />
      <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
        <p>
          Please do not go back or refresh the page. Your payment is being
          processed...
        </p>
      </div>
    </div>
  );
};

function FallbackLoader() {
  return (
    <div id="loader-wrapper">
      <img src={PLACEHOLDER_NETRE} alt="" style={{ width: "85px" }} />
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
